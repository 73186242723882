<template>
  <!-- BD店铺维护 -->
  <div class="container">
    <div class="body">
      <div class="dataTable">
        <el-table
          style="font-family: PingFangRoutine"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column prop="name"  label="店铺名称"></el-table-column>
          <el-table-column prop="createTime"  label="维护时间"></el-table-column>
        </el-table>
      </div>
    </div>
     <div style="height: 10px"></div>
    <el-card class="bottom">
      <!-- 分页 -->
      <div class="button" @click="btn">
          打印
      </div>

      <!-- 分页 -->
    </el-card>

  </div>
</template>
<script>
import {bdGetStore } from "@/api/bd";
import "@/font-style/font.css";
export default {
  data() {
    return {
      queryinfo: {
        endTime:'',//结束时间
        startTime:'',//开始时间
        promoterId:''//主键id
      },
      datatime:[],//日期数组
      total:0,//总条数
      tableData: [], // 表格数据
    };
  },
  created() {
    this.date()
  },

  methods: {
    //初始日期
    date(){
      this.queryinfo.promoterId=this.$route.query.id
      this.queryinfo.startTime=this.$route.query.startTime
      this.queryinfo.endTime=this.$route.query.endTime
      this.list()
    },
    //table样式
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //BD员工列表
    list() {
         bdGetStore(this.queryinfo).then(res=>{
           this.total = this.tableData.length
           this.tableData=res.data
         })
    },
    btn(){
      window.print()
    },
  },
};
</script>
 <style lang="less" scoped>
@import "../../../../assets/css/common.less";
.bottom {
    display: flex;
    align-items: center;
    justify-content: center;
}
.button{
      border-width: 0px;
      width: 90px;
      height: 35px;
      background: inherit;
      background-color: rgba(0, 102, 255, 1);
      border: none;
      border-radius: 5px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      color: white;
}
#btn {
  width: 90px;
  margin-right: 50px;
}
/deep/ #remark {
  height: 100px;
  width: 260px;
}
.el-textarea {
  height: 100px;
}
/deep/ .cell{
    text-align: center;
}
/deep/ .OperInput{
    display: flex;
    margin-top: -5px;
}
</style>
